<template>
  <div class="page-custom page-independent container-fluid pt-4 pb-5">
    <div class="container mt-2">
      <div class="row" v-if="loaded">
        <div class="col-xl-1"></div>
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary mb-4">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t.translate("TTL_REJECT_ASSESSMENT_CHECKLIST") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <AssessmentChecklist
              @change="rejectedList"
              :reject="true"
              :data="Subscription"
            />
          </card>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_CANCEL") }}
      </el-button>
      <el-button type="primary" class="" @click="confirmReject($refs)">
        {{ $t.translate("BTN_RETURN") }}
      </el-button>
    </div>
  </div>

  <ApplicationModal ref="select" />
</template>
<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import AssessmentChecklist from "@/components/AssessmentChecklist";
import ApplicationModal from "@/components/ApplicationModal";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  components: {
    AssessmentChecklist,
    ApplicationModal,
  },
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let Subscription = ref({});
    let RejectedArr = ref([]);

    onMounted(async () => {
      let info = await get("/application/view", { ApplicationKey: route.query.key });
      Subscription.value = info?.Application;
      loaded.value = true;
    });

    const confirmReject = async ($refs) => {
      $refs.select.reject(async (Remark, Attachment) => {
        let res = await post("/application/progress/reject", {
          ApplicationKey: route.query.key,
          Remark,
          Attachment,
          IsReturnToClient: true,
          CheckList: RejectedArr.value,
        });

        if (isValidResponse(res)) {
          router.goBack();
        }
      });
    };

    const rejectedList = (arr) => {
      RejectedArr.value = arr;
    };

    return { confirmReject, loaded, Subscription, rejectedList };
  },
};
</script>
